<template>
  <v-container fluid>
    <v-card outlined class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          :label="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.isInRole(103)"
          color="primary"
          elevation="0"
          dense
          class="ma-1"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>
      <!-- :headers="headers" 
        :items="testProfileRequest"
        -->

      <v-data-table
        group-by="collectionId"
        group-desc
        show-group-by
        :headers="headers"
        :items="testProfileRequest"
        :search="search"
        :loading="loading"
        :loading-text="$t('loading')"
        class="mt-8"
        :items-per-page="10"
        show-select
        v-model="selectedRequest"
        item-key="guid"
      >
        <template v-slot:[`group.header`]="props">
          <td :colspan="headers.length + 1">
            <v-btn icon @click="props.toggle">
              <v-icon v-if="props.isOpen">
                mdi-chevron-up-circle-outline
              </v-icon>
              <v-icon v-else> mdi-chevron-down-circle-outline </v-icon>
            </v-btn>
            <v-btn
              text
              outlined
              class="mx-5"
              color="primary"
              @click="printCollection(props.group)"
            >
              {{ $t("print") }}
              <v-icon class="mx-1"> mdi-printer </v-icon>
            </v-btn>
          </td>
        </template>

        <template v-slot:[`item.testPrice`]="{ item }">
          {{ item.testPrice | number("0,0") }}
        </template>
        <template v-slot:[`item.dateReg`]="{ item }">
          {{ item.dateReg | moment("YYYY/MM/DD hh:mm A") }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  color=""
                >
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip> -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  color="red darken-2"
                >
                  <v-icon> mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <Dialog
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :resetValidation="resetValidation"
      :patients="patients"
      :testProfiles="testProfiles"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
//import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  components: { ConfirmDialog, Dialog },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      testProfileRequest: [],
      patients: [],
      testProfiles: [],
      selectedRequest: [],
      editedItem: {},
      defaultItem: {},
      resetValidation: 0,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },

  computed: {
    headers() {
      var list = [
        {
          text: this.$t("patients.patientName"),
          value: "patientName",
          groupable: false,
        },
        {
          text: this.$t("tests.testCode"),
          value: "profileCode",
          groupable: false,
        },
        {
          text: this.$t("tests.testName"),
          value: "profileName",
          groupable: false,
        },
        {
          text: this.$t("tests.testPrice"),
          value: "testPrice",
          type: "number",
          format: "0,0",
          groupable: false,
        },
        {
          text: this.$t("date"),
          value: "dateReg",
          type: "date",
          format: "YYYY/MM/DD hh:ss a",
          groupable: false,
        },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
        groupable: false,
      };

      // if (this.$store.getters.isInRole(7)) {
      //   actions.templates.push({
      //     type: "btn",
      //     icon: "mdi-pencil",
      //     text: this.$t("edit"),
      //     click: this.editItem,
      //   });
      // }

      if (this.$store.getters.isInRole(105)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();
    this.getPatients();
    this.getProfiles();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // selectedRequest(val) {
    //   console.log(val);
    // },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.testProfileRequest.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.testProfileRequest.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.testProfileRequest[this.editedIndex];
      this.$axios
        .delete("TestProfileRequest/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      this.$axios
        .get("TestProfileRequest")
        .then((response) => {
          this.testProfileRequest = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getPatients() {
      this.$axios
        .get("Patient")
        .then((response) => {
          this.patients = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },

    getProfiles() {
      this.$axios
        .get("TestProfileCharge")
        .then((response) => {
          this.testProfiles = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },
    printCollection(collectionId) {
      //console.log(collectionId);
      //console.log(this.selectedRequest);

      let postConfig = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        responseType: "blob",
      };
      var data = {
        collectionId: collectionId,
        selectedRequest: this.selectedRequest.map((m) => m.guid),
      };
      this.$axios
        .post("TestProfileRequest/Print", data, postConfig)
        .then((response) => {
          if (response.status == 200) {
            var file = new Blob([response.data], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            this.$toast.error("Some thing went wrong");
          }
        })
        .catch((e) => {
          this.$toast.error("Some thing went wrong");
          console.log(e);
        });
    },
  },
};
</script>

<style></style>
