<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
              (editedIndex === -1 ? $t("add") : $t("edit")) +
              " " +
              $t("tests.testBody")
            }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <patient-select
                  @patientChange="patientChange"
                  :editedIndex="editedIndex"
                />

                <!-- <v-autocomplete
                  :readonly="editedIndex != -1"
                  persistent-hint
                  v-model="patientGuid"
                  :rules="[rules.required]"
                  :label="$t('patients.patientName')"
                  item-text="patientDisplayName"
                  item-value="guid"
                  :items="filteredItems"
                  @focus="onFocus"
                >
                  <template v-slot:append-item>
                    <div v-intersect="endIntersect" />
                  </template>
                  <template v-slot:prepend-item>
                    <v-text-field
                      v-model="searchPatient"
                      ref="autocomplete"
                      @input="patientKeyUp"
                      :label="$t('search')"
                    />
                  </template>

                  <template v-slot:item="{ item }">
                    <div v-html="highlightMatch(item.patientDisplayName)"></div>
                  </template>
                </v-autocomplete> -->
              </v-col>
              <v-col cols="12" md="12">
                <v-menu
                  :close-on-click="true"
                  :close-on-content-click="false"
                  rounded="0"
                  v-model="menu"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-textarea
                      :value="testNames"
                      v-bind="attrs"
                      v-on="on"
                      :label="$t('tests.testName')"
                      hide-details
                      readonly
                      rows="1"
                      dir="ltr"
                      auto-grow
                    >
                    </v-textarea>
                  </template>

                  <v-card flat>
                    <v-card-title>
                      <v-text-field
                        v-model="search"
                        :label="$t('search')"
                        hide-details
                      ></v-text-field>
                      <v-spacer />
                      <v-btn icon color="red" @click="menu = false">
                        <v-icon> mdi-close-circle-outline </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card flat height="250" style="overflow-y: auto">
                      <v-data-table
                        v-model="testProfileList"
                        :search="search"
                        :items-per-page="-1"
                        :headers="headers"
                        :items="testProfiles"
                        hide-default-footer
                        item-key="guid"
                        show-select
                        :single-select="editedIndex != -1"
                      >
                      </v-data-table>
                    </v-card>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import PatientSelect from "@/components/PatientSelect.vue";
export default {
  components: { PatientSelect },
  props: [
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
    "patients",
    "testProfiles",
  ],
  data() {
    return {
      valid: true,
      menu: false,
      search: "",
      searchPatient: "",
      patientGuid: "",
      testProfileList: [],
      patientPage: 0,
      patientsList: [],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
    // patientGuid(val) {
    //   console.log(val);
    // },
    editedItem(val) {
      // console.log(val);
      if (val && val.testProfileChargeGuid) {
        var profile = this.testProfiles.filter(
          (f) => f.guid == val.testProfileChargeGuid
        );
        this.testProfileList = profile;

        this.$axios.get("Patient?guid=" + val.patientGuid).then((response) => {
          // console.log(response);

          if (response.data.data.length > 0) {
            this.patientPage = 0;
            this.patientsList = [response.data.data.patient];
            // console.log(this.patientsList);
          }
        });
      }
    },
  },
  created() {
    this.patientsList = this.patients;
  },
  computed: {
    headers() {
      return [
        { text: this.$t("tests.testCode"), value: "profileCode" },
        { text: this.$t("tests.testName"), value: "profileName" },
        { text: this.$t("tests.testPrice"), value: "testPrice" },
      ];
    },
    filteredItems() {
      // Filter the list of patients based on the search query

      if (this.searchPatient) {
        return this.patientsList.filter((patient) => {
          return patient.patientDisplayName.includes(this.searchPatient);
        });
      } else {
        return this.patientsList;
      }
    },
    testNames() {
      //return this.testProfileList.map((x) => x.profileName);
      //console.log(this.testProfileList.map((x) => x.profileName));
      return this.testProfileList.map((x) => x.profileName).join(" , ");
    },
  },
  mounted() {
    // Focus the search input field when the autocomplete is focused
    if (this.$refs.autocomplete && this.$refs.searchInput) {
      // Focus the search input field when the autocomplete is focused
      this.$refs.autocomplete.$el.addEventListener("focus", () => {
        this.$refs.searchInput.focus();
      });
    }
  },
  methods: {
    patientChange(val) {
      if (val) {
        this.patientGuid = val;
      }
    },
    onFocus() {
      // Do something when the autocomplete is focused
      setTimeout(() => {
        this.$refs["autocomplete"].focus();
      }, 200);
    },
    highlightMatch(text) {
      // Escape special characters in the search query

      if (this.searchPatient && this.searchPatient != "") {
        const escapedFilter = this.searchPatient.replace(
          /[.*+?^${}()|[\]\\]/g,
          "\\$&"
        );
        // Use a regular expression to highlight the matched text
        const highlightedText = text.replace(
          new RegExp(escapedFilter, "gi"),
          "<mark style='background-color: #d1d1d1; border-radius: 2px;'>$&</mark>"
        );
        return highlightedText;
      }
      return text;
    },

    resetForm() {
      this.testProfileList = [];
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    endIntersect(_entries, _observer, isIntersecting) {
      if (isIntersecting == true) {
        this.getPatientsByPage();
      }
    },
    patientKeyUp() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      // Set a new timeout to call the API after 2 seconds
      this.timeout = setTimeout(() => {
        this.getPatientsByPage();
      }, 1500);
    },
    getPatientsByPage() {
      this.$axios
        .get(
          "Patient/GetByPages?skip=" +
            this.patientPage +
            "&filter=" +
            this.searchPatient
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.patientPage += response.data.data.length ?? 0;
            this.patientsList = [...this.patientsList, ...response.data.data];
          }
        });
    },
    save() {
      var val = this.$refs.form.validate();

      if (val) {
        var item = {};
        item.patientGuid = this.patientGuid;
        item.testProfileList = this.testProfileList.map((m) => m.guid);
        //console.log(this.testProfileList[0].guid);
        if (this.editedIndex > -1) {
          this.$axios
            .get(
              "TestProfileRequest/Update?guid=" +
                this.editedItem.guid +
                "&profileGuid=" +
                this.testProfileList[0].guid
            )
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        } else {
          this.$axios
            .post("TestProfileRequest/Add", item)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
                this.testProfileList = [];
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
